import React, { useState, useEffect } from 'react';
import CountdownTimer from './CountdownTimer ';
import Web3 from 'web3';
import copy from 'clipboard-copy';
import { toast } from 'react-toastify';
import bigDecimal from "js-big-decimal";
import { buyWithB, buyWithU, getDataSM, dataWeb, submitApproveStaking, checkApproveStaking } from "../../services/smartContract";
import { abi_staking, contractStaking, abi_usdt, contractUsdt, contractBNB, abi_bnb } from '../../setup/USDT';
import { convertNumber } from "../../setup/fc";
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LineCoin from './LineCoin';
function Presale(props) {
    const bigUnit = new bigDecimal("1000000000000000000");
    const [statusBtn, setStatusBtn] = useState(0);
    const [lineCoin, setlineCoin] = useState('');
    const [priceToken, setPriceToken] = useState(0);
    const [rateBnbUsdt, setRateBnbUsdt] = useState(0);

    const [typePay, setTypePay] = useState(0);
    const [walletBNB, setWalletBNB] = useState(0);
    const [walletUSDT, setWalletUSDT] = useState(0);
    const [amountBuy, setAmountBuy] = useState(0);
    const [loading, setLoading] = useState(0);

    const [priceTokenNext, setPriceTokenNext] = useState(0.016);
    const [totalCoin, setTotalCoin] = useState(30000000);
    const [coinSM, setCoinSM] = useState(0);
    const [coinWB, setCoinWB] = useState(500000);
    const [timeEnd, setTimeEnd] = useState('2024-08-16T23:59:59');
    const [idAff, setidAff] = useState(0);
    const [ApproveUSDT, setApproveUSDT] = useState(0);
    const [ApproveQty, setApproveQty] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            await fc_start();

        };
        fetchData();
    }, []);

    const fc_start = async () => {
        setPriceToken('0.01');
        setRateBnbUsdt(702);
        await checkConnect();

        let aff = 0;

        const sAff = JSON.parse(localStorage.getItem('aff'));
        if (sAff) {
            aff = sAff;
        }

        const params = new URLSearchParams(window.location.search);
        const myAff = params.get('aff');
        if (myAff) {
            aff = myAff;
        }

        setidAff(aff);

    }
    const walletConnection = async () => {
        if (window.ethereum) {
            try {

                const web3 = new Web3(window.ethereum);
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                const accounts = await web3.eth.getAccounts();
                const userAddress = accounts[0];
                const balance = await web3.eth.getBalance(accounts[0]);
                let bnbBalance = Number(new bigDecimal(balance).divide(bigUnit).value);

                const contract = new web3.eth.Contract(
                    abi_usdt,
                    contractUsdt
                );
                let getAmountToken = await contract.methods.balanceOf(accounts[0]).call();
                let usdtBalance = Number(new bigDecimal(getAmountToken).divide(bigUnit).value);

                setWalletBNB(bnbBalance);
                setWalletUSDT(usdtBalance);
                setStatusBtn(1);
            } catch (error) {
                toast.error('Error:');
            }
        } else {
            toast.error('Please install MetaMask!');
        }
    }

    const checkConnect = async () => {

        const rWeb = await dataWeb();
        setPriceTokenNext(rWeb.priceNext);
        console.log(rWeb.timeEnd);
        setTimeEnd(rWeb.timeEnd);
        const setUpdata = await getDataSM();

        setPriceToken(Number(setUpdata.rateTokenUsdt) / 1e6);
        setRateBnbUsdt(Number(setUpdata.rateBnbUsdt) / 1e6);

        if (typeof window.ethereum !== 'undefined') {
            try {
                const web3 = new Web3(window.ethereum);
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                const accounts = await web3.eth.getAccounts();
                const userAddress = accounts[0];
                const balance = await web3.eth.getBalance(accounts[0]);
                let bnbBalance = Number(new bigDecimal(balance).divide(bigUnit).value);

                const contract = new web3.eth.Contract(
                    abi_usdt,
                    contractUsdt
                );
                let getAmountToken = await contract.methods.balanceOf(accounts[0]).call();
                let usdtBalance = Number(new bigDecimal(getAmountToken).divide(bigUnit).value);
                const checkApp = await checkApproveStaking();

                if (checkApp) {
                    setApproveUSDT(checkApp.stt);
                    setApproveQty(checkApp.balance);
                }

                setWalletBNB(bnbBalance);
                setWalletUSDT(usdtBalance);
                setStatusBtn(1);
            } catch (error) {
                console.error('User denied account access');
            }
        } else {
            console.error('Metamask is not installed');
        }
    }
    const lineCoinHtml = async () => {

        let pt = (((Number(coinSM) + Number(coinWB)) / totalCoin) * 100);
        const divStyle = {
            width: pt + '%'
        };
        return (
            <>
                <div className='tokens-sold-line'>
                    <div className='tokens-sold-sell' style={divStyle}></div>
                    <span>{convertNumber((Number(coinSM) + Number(coinWB)))} / {convertNumber(totalCoin)}</span>
                </div>
            </>
        );
    }
    const btnMax = () => {
        if (typePay == 0) {
            setAmountBuy(walletBNB)
        } else {
            setAmountBuy(walletUSDT)
        }
    }

    const btnApprove = async () => {

        if (Number(amountBuy) <= 0) {
            toast.error("Please enter the quantity you want to buy");
            return true;
        }

        let data = await submitApproveStaking(amountBuy);
        if (!data) {
            toast.error("Error");
            setLoading(0);
        } else {
            setApproveUSDT(1);
            setLoading(0);

        }
    }

    const btnBuy = async () => {

        if (loading == 1) {
            return true;
        }

        if (Number(amountBuy) <= 0) {
            toast.error("Please enter the quantity you want to buy");
            return true;
        }
        setLoading(1);

        let aff = 0;

        const sAff = JSON.parse(localStorage.getItem('aff'));
        if (sAff) {
            aff = sAff;
        }

        const params = new URLSearchParams(window.location.search);
        const myAff = params.get('aff');
        if (myAff) {
            aff = myAff;
        }

        let data;
        if (typePay == 0) {
            data = await buyWithB(amountBuy, aff);
        } else {
            data = await buyWithU(amountBuy, aff);
        }

        if (!data) {
            toast.error("Error");
            setLoading(0);
        } else {
            toast.success("Buy Success");
            setLoading(0);

        }
    }
    const copySM = () => {
        copy('0xa5f688F222796129652Ea5eD116Bf23461b0B18A');
        toast.success("The link has been copied");
    }

    return (
        <>
            <div id="sc_presale">
                <div className="container text-center">
                    <div className="row justify-content-end">
                        
                        
                        <div className="col-lg-5">
                            <div className="box-text-presale">
                            <div className='text-dex'><span>WKS will be listed on the exchange on December 25, 2024, with a price of $0.4.</span></div>
                                <img src='images/about.png' />
                            </div>


                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-6">
                            <div className="box-presale">
                                <div className="box-presale-bg">
                                    <h2>Presale</h2>
                                    <div>
                                        <div className='view-sm'>
                                            <button type='button' onClick={() => copySM()}>0xa5f688F222796129652Ea5eD116Bf23461b0B18A <FontAwesomeIcon icon={faCopy} /></button>
                                        </div>
                                        <CountdownTimer endTime={timeEnd} priceTokenNext={priceTokenNext} />
                                        <div className="coin-rate">
                                            <div>
                                                <hr />
                                                <span>${priceToken} = 1 WKS</span>
                                                <hr />
                                            </div>
                                        </div>

                                        <div className="type-pay">
                                            <button type='button' onClick={() => { setTypePay(0); setAmountBuy(0); }} className={typePay == 0 ? 'active btn-payment-bnb' : 'btn-payment-bnb'}>BNB</button>
                                            <button type='button' onClick={() => { setTypePay(1); setAmountBuy(0); }} className={typePay == 1 ? 'active btn-payment-usdt' : 'btn-payment-usdt'}>USDT</button>
                                        </div>
                                        <div className="total-wallet">
                                            {
                                                typePay == 0 ? (
                                                    <><p>BNB Balance: {walletBNB}</p></>
                                                ) : (
                                                    <><p>USDT Balance: {walletUSDT}</p></>
                                                )
                                            }
                                        </div>
                                        <div className="ip-payment">
                                            <div className="gr-ip-payment">
                                                <label> {typePay == 0 ? (<>BNB</>) : (<>USDT</>)} you pay</label>
                                                <input type='text' className="ip-qty" placeholder='0' value={amountBuy}
                                                    onChange={(e) => { let val = e.target.value; setAmountBuy(val) }} />
                                                <button type='button' className='btn-payment-max' onClick={() => btnMax()}>Max</button>
                                            </div>
                                            <div className="gr-ip-payment">
                                                <label>$WKS you receive</label>
                                                <input type='text' placeholder='0' className="ip-receive" readOnly={true} value={typePay == 1 ? (amountBuy / priceToken) : (amountBuy * rateBnbUsdt / priceToken)} />
                                            </div>
                                        </div>
                                        <div className="gr-btn-payment">
                                            {
                                                statusBtn == 0 ? (
                                                    <><button type='button' className='btn-payment-connection' onClick={() => walletConnection()}>Wallet connection</button></>
                                                ) : statusBtn == 1 && typePay == 1 && ApproveUSDT == 0 ? (
                                                    <><button type='button' className='btn-payment-buynow' onClick={() => btnApprove()}>Approve</button></>
                                                ) : statusBtn == 1 && typePay == 1 && ApproveUSDT == 1 ? (
                                                    <><button type='button' className='btn-payment-buynow' onClick={() => btnBuy()}>Buy Now</button></>
                                                ) : (
                                                    <><button type='button' className='btn-payment-buynow' onClick={() => btnBuy()}>Buy Now</button></>
                                                )
                                            }

                                        </div>
                                       
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Presale;